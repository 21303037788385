/*
 * @Description: AKJERRT
 * @Date: 2022-09-15 11:59:22
 * @LastEditTime: 2022-09-15 15:52:32
 * @FilePath: \linkgap-front\src\views\aarget_budget\components\basis.js
 */
export const mixin = {
  data() {
    return {
      depts: [],
      firms: []
    }
  },
  mounted() {
    this.axios.get('/api/base/system/department/all').then((res) => {
      const data = res.body
      this.buildtree(data, this.depts, 0)
    });
    this.axios.post('/api/firm/mhiac/mhiacFirm/getTree').then((res) => {
      const data = res.body
      const firmList = []
      console.log('----1-----',data)
      for (var i = 0; i < data.length; i++) {
        const childrenList = []
        if (data[i].children.length > 0) {
          data[i].children.forEach((items) => {
            childrenList.push({
              value: items.key,
              key: items.key,
              title: items.title
            })
          })
        }
        firmList.push({
          value:data[i].key,
          key:data[i].key,
          title:data[i].title,
          children:childrenList
        })
      }
      console.log('----2-----',firmList)
      this.firms = firmList
    });
  },
  methods: {
    submit() {
      console.log('提交');
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          var child = {
            key: item.id,
            value: item.id, // value是给modal的select用的，2者属性不一样
            title: item.name,
            scopedSlots: { title: 'title' },
            children: [],
          }
          this.buildtree(list, child.children, item.id)
          if (child.children.length === 0) {
            delete child.children
          }
          arr.push(child)
        }
      })
    },
  }
};